import React, { Fragment } from 'react'

import { NextPage, NextPageContext } from 'next'

import { ProductionScripts } from '@/components/ProductionScripts'

export function withProductionScript<P extends object>(
  PageComponent: NextPage<P>,
): NextPage {
  const EnhancedPageComponent: NextPage<P> = (props: P) => {
    return (
      <Fragment>
        <ProductionScripts />

        <PageComponent {...props} />
      </Fragment>
    )
  }

  EnhancedPageComponent.getInitialProps = async function (
    ctx: NextPageContext,
  ) {
    let pageProps = {}

    if (typeof PageComponent.getInitialProps === 'function') {
      pageProps = await PageComponent.getInitialProps(ctx)
    }

    return pageProps as P
  }

  return EnhancedPageComponent as NextPage
}
