import { get, flowRight as compose } from 'lodash'

import withStats from '../stats/withStats'
import withPrivateRoute from '../auth/withPrivateRoute'
import withErrorHandling from './withErrorHandling'
import { withProductionScript } from './withProductionScript'

export default function withPage(options = {}) {
  const isPrivatePage = get(options, 'isPrivate', false)

  return function (Component) {
    return compose(
      withProductionScript,
      withStats,
      withErrorHandling,
      withPrivateRoute(isPrivatePage),
    )(Component)
  }
}
