import React, { Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useToast } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import { NextSeo } from 'next-seo'

import withPage from '@/lib/page/withPage'
import LazyLoad from '@/lib/LazyLoad'

import {
  Header,
  Hero,
  Preparation,
  WhyUs,
  OurServices,
  WhatYouGet,
  Footer,
  CheckWebsite,
  ContactUs,
  FAQ,
  GetStarted,
  Testimonial,
  AsSeenOn,
  OtherProducts,
  PolicyCount,
  SiteReference,
  Certified,
  SiteUpdate,
} from '@/components/Landing'

const { publicRuntimeConfig } = getConfig()

const DynamicBlog = dynamic(
  () => {
    return import('@/components/Landing').then((mod) => mod.Blog)
  },
  { ssr: false },
)

const DynamicContact = dynamic(
  () => {
    return import('@/components/Landing').then((mod) => mod.Contact)
  },
  { ssr: false },
)

function Home() {
  const { query } = useRouter()
  const toast = useToast()

  const BLOG_MIN_HEIGHT = '1024px'

  useEffect(() => {
    if (query.account_confirmation_success === 'true') {
      toast({
        title: 'ยืนยันอีเมล์เรียบร้อย',
        status: 'success',
        isClosable: true,
        position: 'top',
      })
    }
  }, [])

  return (
    <Fragment>
      <NextSeo title="สร้าง Privacy Policy แบบมือโปร" />

      <div css={{ overflow: 'hidden' }}>
        <Header />
        <Hero />
        <Preparation />
        <SiteUpdate />
        <PolicyCount count={publicRuntimeConfig.POLICY_COUNT || 0} />
        <SiteReference />
        <CheckWebsite />
        <WhyUs />
        <OurServices />
        <WhatYouGet />
        <GetStarted />
        <Testimonial />
        <AsSeenOn />
        <OtherProducts />
        <Certified />
        <FAQ />
        <div style={{ minHeight: BLOG_MIN_HEIGHT }}>
          <LazyLoad>
            <DynamicBlog />
          </LazyLoad>
        </div>

        <ContactUs />
        <LazyLoad>
          <DynamicContact />
        </LazyLoad>
        <Footer />
      </div>
    </Fragment>
  )
}

export default withPage()(Home)
