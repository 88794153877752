import React from 'react'
import { InView } from 'react-intersection-observer'

const defaultOptions = {
  rootMargin: '0px 0px 800px 0px',
  triggerOnce: true,
}

function LazyLoad({ children, options = defaultOptions }) {
  return (
    <InView {...options}>
      {({ ref, inView }) => <div ref={ref}>{inView && children}</div>}
    </InView>
  )
}

export default LazyLoad
