import { Fragment } from 'react'
import Script from 'next/script'

import env from '@/config/env'

export const ProductionScripts = (): React.ReactElement => {
  const isProd = !env.IS_DEV

  return (
    <Fragment>
      {isProd && (
        <Fragment>
          <Script
            id="cwc-script"
            type="text/javascript"
            src="https://script.cookiewow.com/cwc.js"
            strategy="beforeInteractive"
          />

          <Script
            id="cookieWow"
            type="text/javascript"
            src={`https://script.cookiewow.com/configs/${env.COOKIE_BANNER_ID}`}
            data-cwcid={env.COOKIE_BANNER_ID}
            strategy="beforeInteractive"
          />

          <Script id="gtm" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${env.GTM_CONTAINER_ID}')
            `}
          </Script>

          <Script id="fbp" strategy="lazyOnload">
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2773010969579317');
              fbq('track', 'PageView');
            `}
          </Script>

          {/* <Script id="howuku" strategy="lazyOnload">
            {`
              (function(d){
                var key = "834MV9Wnqd0AEYXrGNLpJw",
                e = d.createElement("script");
                e.type = "text/javascript";
                e.async = true;
                e.src = "https://cdn.howuku.com/js/howu.js";
                e.setAttribute("key", key);
                d.getElementsByTagName("head")[0].appendChild(e);
              })(document);
            `}
          </Script> */}

          <Script id="hotjar" strategy="lazyOnload"></Script>

          <Script id="wiser" strategy="lazyOnload">
            {`
              !function(){if(window.t4hto4)console.log("WiserNotify pixel installed multiple time in this page");
              else { window.t4hto4 = !0; var t = document, e = window,
                n = function () { var e = t.createElement("script");
                e.type = "text/javascript",
                e.async = !0,
                e.src = "https://pt.wisernotify.com/pixel.js?ti=1jkhiljkh5n4khd",
                document.body.appendChild(e) };
                "complete" === t.readyState ? n() : window.attachEvent ?
                e.attachEvent("onload", n) : e.addEventListener("load", n, !1) } }();
            `}
          </Script>
        </Fragment>
      )}
    </Fragment>
  )
}
