import React from 'react'
import { get } from 'lodash'

import { Error400, Error500 } from '@/components/Error'

export default function withErrorHandling(PageComponent) {
  function EnhancedPageComponent(props) {
    if (props.statusCode >= 500) {
      return <Error500 />
    }

    if (props.statusCode >= 404) {
      return <Error400 />
    }

    return <PageComponent {...props} />
  }

  EnhancedPageComponent.getInitialProps = async function (ctx) {
    let pageProps = {}

    if (typeof PageComponent.getInitialProps === 'function') {
      try {
        pageProps = await PageComponent.getInitialProps(ctx)
      } catch (error) {
        const statusCode = get(error, 'response.status', 500)
        const errorCode = get(error, 'response.errorCode', '')
        
        if (ctx.res) {
          ctx.res.statusCode = statusCode
        }

        pageProps = {
          ...pageProps,
          statusCode,
          errorCode,
        }
      }
    }

    return pageProps
  }

  return EnhancedPageComponent
}
