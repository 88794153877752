import React from 'react'

import { redirectPage } from '@/lib/helpers'

const isPrivateRoute = (isPrivate) => (PageComponent) => {
  function EnhancedPageComponent(props) {
    return <PageComponent {...props} />
  }

  EnhancedPageComponent.getInitialProps = async function (ctx) {
    let pageProps = {}

    if (isPrivate) {
      const { isAuth, needChangePassword, asPath } = ctx

      if (needChangePassword && asPath !== '/password-expiration') {
        redirectPage(
          {
            path: '/password-expiration',
          },
          ctx,
        )

        return pageProps
      }

      if (!isAuth) {
        redirectPage(
          {
            path: '/login',
            params: {
              redirect_url: asPath,
            },
          },
          ctx,
        )

        return pageProps
      }
    }

    if (typeof PageComponent.getInitialProps === 'function') {
      pageProps = await PageComponent.getInitialProps(ctx)
    }

    return pageProps
  }

  return EnhancedPageComponent
}

export default isPrivateRoute
